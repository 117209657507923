import React from "react"
import PropTypes from "prop-types"
import * as styles from "./PageDescription.module.scss"
import { Container } from "react-bootstrap"
import WysiwygContent from "./WysiwygContent"

const PageDescription = ({ description }) => {
  return (
    <Container className={styles.container}>
      <WysiwygContent
        dangerouslySetInnerHTML={{ __html: description }}
        className={styles.description}
      />
    </Container>
  )
}

PageDescription.propTypes = {
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

export default PageDescription
