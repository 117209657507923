import React from "react"
import PropTypes from "prop-types"
import * as styles from "./PageFeaturedImage.module.scss"
import { Container } from "react-bootstrap"
import UniversalImage from "./UniversalImage"

const PageFeaturedImage = ({ node }) => {
  return node ? (
    <Container className={styles.container}>
      <UniversalImage image={node} className={styles.featuredImage} />
    </Container>
  ) : null
}

PageFeaturedImage.propTypes = {
  node: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

export default PageFeaturedImage
