import SEO from "components/common/Seo"
import PageTitle from "components/common/ui/PageTitle"
import Layout from "components/layouts/Layout"
import { graphql } from "gatsby"
import React, { useEffect } from "react"
import BlocksLayout from "components/layouts/BlocksLayout"
import PageDescription from "components/common/ui/PageDescription"
import PageFeaturedImage from "components/common/ui/PageFeaturedImage"

const leftColAttrs = {
  md: 6,
}

const rightColAttrs = {
  md: 6,
}

const Page = ({ data, pageContext }) => {
  const {
    page: {
      title,
      parent,
      pageAttributes: {
        showTitle,
        showDescription,
        description,
        showFeaturedImage,
        background,
        redirectToExternalPage,
        redirectToExternalPageUrl,
        loadFile,
        fileToLoad,
      },
      pageBuilder: { blocks },
      featuredImage,
    },
  } = data

  useEffect(() => {
    if (loadFile) {
      window.location = fileToLoad?.localFile?.publicURL
    }
  }, [fileToLoad, loadFile])

  useEffect(() => {
    if (redirectToExternalPage) {
      window.location = redirectToExternalPageUrl
    }
  }, [redirectToExternalPage, redirectToExternalPageUrl])

  return !redirectToExternalPage && !loadFile ? (
    <Layout background={background} page={pageContext?.page}>
      <SEO {...pageContext.seo} />
      {showTitle && <PageTitle title={title} parentNode={parent?.node} />}
      {showDescription && <PageDescription description={description} />}
      {showFeaturedImage && <PageFeaturedImage node={featuredImage?.node} />}
      {blocks && (
        <BlocksLayout
          blocks={blocks}
          leftColAttrs={leftColAttrs}
          rightColAttrs={rightColAttrs}
        />
      )}
    </Layout>
  ) : null
}

export const query = graphql`
  query post($id: String!) {
    page: wpPage(id: { eq: $id }) {
      ...PageTemplateFragment
    }
  }
`

export default Page
